<template>
  <div class="card"> <!-- Dates -->
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Dates</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseSection-2`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseSection-2`"
        visible
      >
        <div class="card-body">
          <div
            v-if="type != 'promotion'"
            class="row"
          >
            <div class="col-sm-3">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >{{ labels['dates.date_open_access'] }}</label>
                <date-picker
                  v-model="convo.start_date"
                  format="D MMM Y HH:mm:ss"
                  value-type="format"
                  :lang="lang"
                  class="w-100"
                  @change="submitAnswer($event, 'start_date')"
                />
                <!-- <input
                  class="form-control"
                  :value="convo.start_date"
                  type="date"
                  @change="submitAnswer($event, 'start_date')"
                > -->
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >{{ labels['dates.date_closed_access'] }}</label>
                <date-picker
                  v-model="convo.end_date"
                  format="D MMM Y HH:mm:ss"
                  :lang="lang"
                  value-type="format"
                  class="w-100"
                  @change="submitAnswer($event, 'end_date')"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >{{ labels['dates.date_meeting'] }}</label>
                <date-picker
                  v-model="convo.meeting_day"
                  format="D MMM Y"
                  value-type="format"
                  :lang="lang"
                  class="w-100"
                  @change="submitAnswer($event, 'meeting_day')"
                />
              </div>
            </div>
          </div>
          <div
            v-if="type == 'promotion'"
            class="row"
          >
            <div class="col-sm-2">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >{{ labels['dates.date_start_promotion_publication'] }}</label>
                <date-picker
                  v-model="convo.start_date"
                  format="D MMM Y"
                  value-type="format"
                  :lang="lang"
                  class="w-100"
                  @change="submitAnswer($event, 'start_date')"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >{{ labels['dates.date_end_promotion_publication'] }}</label>
                <date-picker
                  v-model="convo.end_date"
                  format="D MMM Y"
                  value-type="format"
                  :lang="lang"
                  class="w-100"
                  @change="submitAnswer($event, 'end_date')"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >Day of the meeting for the final act</label>
                <date-picker
                  v-model="convo.meeting_day"
                  format="D MMM Y"
                  value-type="format"
                  :lang="lang"
                  class="w-100"
                  @change="submitAnswer($event, 'meeting_day')"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >
                  {{ labels['dates.date_deadline_peers'] }}
                </label>
                <date-picker
                  v-model="convo.deadline_evaluators"
                  format="D MMM Y"
                  :lang="lang"
                  value-type="format"
                  class="w-100"
                  @change="submitAnswer($event, 'deadline_evaluators')"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="mb-1 position-relative">
                <label
                  for=""
                  class="form-label"
                >{{ labels['dates.date_deadline_researchers'] }}</label>
                <date-picker
                  v-model="convo.deadline_report"
                  format="D MMM Y HH:mm:ss"
                  value-type="format"
                  :lang="lang"
                  class="w-100"
                  @change="submitAnswer($event, 'deadline_report')"
                />
              </div>
            </div>
            <hr>
            <h5>Peers Date pending send mail</h5>
            <p>{{ labels['dates.peers_date_pending_send_email_description'] }}</p>
            <div class="row">
              <div class="col-sm-3">
                <div class="mb-1 position-relative">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['dates.date_peers_date_pending_send_email'] }}</label>
                  <date-picker
                    v-model="convo.peers_date_mail"
                    format="D MMM Y"
                    value-type="format"
                    :lang="lang"
                    class="w-100"
                    @change="submitAnswer($event, 'peers_date_mail')"
                  />
                </div>
              </div>
              <div class="col-sm-2">
                <span
                  id="type-success"
                  class="btn btn-primary mt-2"
                  @click="sendEmails(0)"
                >
                  <i data-feather="send" />
                  <span class="ms-25 align-middle">Send email to hosts ...</span>
                </span>
              </div>
              <div class="col-sm-3">
                <span
                  id="type-success"
                  class="btn btn-primary mt-2"
                  @click="sendEmails(1)"
                >
                  <i data-feather="send" />
                  <span class="ms-25 align-middle">Send reminders to hosts ...</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <ItemInfoSheet :width="'40vw'">
      <template #info-sheet-title>
        Host resume - Total: {{ Object.keys(hosts).length }} (selected: {{ Object.keys(selectedHosts).length }})
        <br> <small>(solo se envia a los hosts, no a los researchers)</small>
      </template>
      <template #info-sheet>
        <div class="offcanvas-body">
          <table class="table table-stripe">
            <thead>
              <tr>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                  class="cursor-pointer"
                >
                  <input
                    checked
                    type="checkbox"
                    @click="selectAll($event.target.checked)"
                  >
                </td>
                <td style="background-color: var(--icrea-brand-bg-light);">
                  Researcher
                </td>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                >
                  Host
                </td>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                >
                  Institution
                </td>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                >
                  Email
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="host, index in hosts"
                :key="index"
              >
                <td>
                  <input
                    :checked="selectedAll"
                    type="checkbox"
                    @click="select($event.target.checked, index)"
                  >
                </td>
                <td>{{ index }}</td>
                <td>{{ host[0] }}</td>
                <td>{{ host[1] }}</td>
                <td>{{ host[2] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!loading"
          class="offcanvas-footer mt-auto"
        >
          <div class="row">
            <div class="col-6">
              <a
                href="#"
                title="apply actions"
                class="btn btn-primary mb-1 d-grid w-100"
                @click="applyActions"
              >Send mails</a>
            </div>
            <div class="col-6">
              <a
                href="#"
                title="apply actions"
                class="btn btn-secondary mb-1 d-grid w-100"
                @click="exportExcel"
              >Export excel</a>
            </div>
          </div>

        </div>
        <div
          v-if="loading"
          class="offcanvas-footer mt-auto"
        >
          <a
            title="apply actions"
            class="btn btn-primary mb-1 d-grid w-100 disabled"
          >Sending...</a>
        </div>

      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    DatePicker,
    ItemInfoSheet,
  },
  props: {
    type: { type: String, required: false, default: '' },
  },
  data() {
    return {
      isReminder: false,
      selectedAll: true,
      selectedHosts: {},
      dates: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      loading: false,
      hosts: [],
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  methods: {
    submitAnswer(data, type) {
      this.dates[type] = data
      this.$emit('saveAnswer', { id: 'dates', data: this.dates })
    },
    async sendEmails(reminder = 0) {
      this.isReminder = reminder
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-all-hosts?reminder=${reminder}`)
      this.hosts = resp.data
      this.selectedHosts = { ...this.hosts }
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    selectAll(event) {
      this.selectedAll = event

      if (event) {
        this.selectedHosts = { ...this.hosts }
      } else {
        this.selectedHosts = {}
      }
    },
    select(checked, index) {
      if (checked) {
        Vue.set(this.selectedHosts, index, { ...this.hosts[index] })
      } else {
        Vue.delete(this.selectedHosts, index)
      }
    },
    exportExcel() {
      Vue.prototype.$export({
        url: `${Vue.prototype.$groupUrl}/convo/${this.convo.id}/export-hosts`,
        filters: { users: this.selectedHosts },
        name: 'host_mails',
      })
    },
    applyActions() {
      Vue.swal({
        title: `Do you want to send mails to the selected hosts (${Object.keys(this.selectedHosts).length})?`,
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(result => {
        this.loading = true
        if (result.isConfirmed) {
          Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/send-mail-all-hosts`, { users: this.selectedHosts, isReminder: this.isReminder }).then(e => {
            this.loading = false
            if (e.status == 200) {
              Vue.swal('Mails sent successfully', '', 'success')
            } else {
              Vue.swal('Error on sending mails', '', 'error')
            }
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
          })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>
